<template>
  <div>
    <h3 ref="intro" class="mb-2">
      Banking of Curved Roads
    </h3>
    <p>
      Imagine a car going around a circular road with radius \(r\).
      For the car to move along a circular road without skidding,  there must be a net force acting towards the center of the circle to provide the necessary centripetal force.
      <br><br>
      By banking the curved road, the necessary centripetal force can be supplied by the horizontal component of the normal force.
    </p>
    <h3 ref="formula" class="mb-2">
      Critical Speed on a Banked Road
    </h3>
    <p>
      For every banked curve, there is one speed at which theentire centripetal force can be supplied by the horizontal component of the normal force, and no friction is required. <br>
      This speed is known as the critical speed.
      <br><br>
      For a banked road of radius \(r\) and banking angle \(\theta\), this critical speed is given as &mdash;
      $$v = \sqrt{r g \ \tan\theta}$$
    </p>
    <h3 ref="pg" class="mb-2">
      MagicGraph | Critical Speed on a Banked Roadway
    </h3>
    <h5 class="mb-2">
      Getting Started
    </h5>
    <p>
      This MagicGraph offers a visual-interactive demonstration that offers a step-by-step explanation of critical speed of a car driving on a curved road with a banking angle.
      <br>
    </p>
    <h5 class="mb-2">
      Icons on the MagicGraph
    </h5>
    <p>
      The naviation icons on the MagicGraph help you grasp the concept in a step-by-step manner.<br>
      Tap on <i class="next ma-1" /> button  to go to the next step. Tap on <i class="previous ma-1" /> button to go to the previous step.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'CurvedRoad',
  created () {
    this.$store.commit('navigation/resetState');
    let title = 'Banking on a Curved Road';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    /*let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replacePhys', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Banking on a Curved Road',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about banking on a curved road'}
                ]
        }
   },
}
</script>
